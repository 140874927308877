import React from 'react';

export interface PageTitleProps {
  heading: string;
  description?: string;
  style?: React.CSSProperties;
  titleStyle?: React.CSSProperties;
  descriptionStyle?: React.CSSProperties;
  scrollRef?: React.RefObject<HTMLDivElement>;
}
const PageTitle = ({
  heading,
  description,
  style,
  descriptionStyle,
  titleStyle,
  scrollRef,
}: PageTitleProps) => {
  return (
    <div data-testid="page-title-container" style={{ ...style }}>
      <div ref={scrollRef} style={{ scrollMarginTop: '50px' }} />
      <p
        data-testid="page-title"
        style={{
          textAlign: 'left',
          fontSize: '20px',
          fontWeight: '600',
          marginBottom: '16px',
          ...titleStyle,
        }}
      >
        {heading}
      </p>
      {description && (
        <p
          data-testid="page-description"
          style={{ textAlign: 'left', lineHeight: '22px', ...descriptionStyle }}
        >
          {description}
        </p>
      )}
    </div>
  );
};

export default PageTitle;
