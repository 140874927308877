function getRGB(c) {
  if (c === '00') {
    return 0;
  }
  const result = parseInt(c, 16) || c;
  return result;
}

function getsRGB(c) {
  const a = getRGB(c) / 255 <= 0.03928;
  const b = getRGB(c) / 255 / 12.92;
  const cc = Math.pow((getRGB(c) / 255 + 0.055) / 1.055, 2.4);
  return a ? b : cc;
}

function getLuminance(hexColor) {
  const firstVal = 0.2126 * getsRGB(hexColor.substr(1, 2));
  const secondVal = 0.7152 * getsRGB(hexColor.substr(3, 2));
  const thirdVal = 0.0722 * getsRGB(hexColor.substr(5, 2));

  return firstVal + secondVal + thirdVal;
}

function getContrast(f, b) {
  const L1 = getLuminance(f);
  const L2 = getLuminance(b);
  return (Math.max(L1, L2) + 0.05) / (Math.min(L1, L2) + 0.05);
}

export function getTextColor(bgColor) {
  if (!bgColor) return '#000000';

  const whiteContrast = getContrast(bgColor, '#ffffff');
  const blackContrast = getContrast(bgColor, '#000000');

  return whiteContrast > blackContrast ? '#ffffff' : '#000000';
}

export function hexToRGB(hex) {
  const r = getRGB(hex.slice(1, 3));
  const g = getRGB(hex.slice(3, 5));
  const b = getRGB(hex.slice(5, 7));

  return [r, g, b];
}
