import React, { useContext, useEffect } from 'react';
import { Outlet, useParams } from 'react-router-dom';

import { getClientData } from '../api/talent-rerouting';
import { GlobalContext } from '../context/Global';
import { UserContext } from '../context/User';
import AppHeader from '../components/header-bar/AppHeader';
import FullPageSpinner from '../components/FullPageSpinner';
import NotActive from './NotActive';

const Layout = ({ children }: { children?: React.ReactNode }) => {
  const { client_lookup_name } = useParams();
  const { sessionIsLoading } = useContext(UserContext);
  const {
    isLoading,
    isSubmitting,
    clientData,
    setClientData,
    setClientConfigLoading,
    clientConfigLoading,
  } = useContext(GlobalContext);

  // get theme from lookup name show custom spinner in mean time
  useEffect(() => {
    const getData = async () => {
      setClientConfigLoading(true);
      const clientData = await getClientData(client_lookup_name ?? '');
      setClientData(clientData ?? {});
      setClientConfigLoading(false);
    };

    getData();
  }, [client_lookup_name]);

  const displayNotActive =
    !clientConfigLoading &&
    client_lookup_name !== 'arena' &&
    clientData.isActive === false;
  return (
    <div>
      {(clientConfigLoading || sessionIsLoading) && !displayNotActive && (
        <FullPageSpinner />
      )}{' '}
      {/* this is a spinner replaces the page */}
      {(isLoading || isSubmitting) && <FullPageSpinner />}{' '}
      {/* this is a spinner that overlays the page */}
      {!clientConfigLoading && (
        <div
          style={{
            display: 'flex',
            minHeight: '100vh',
            flexDirection: 'column',
            color: '#232323',
            fontFamily: 'Avenir Next LT Pro',
            fontSize: '14px',
            fontWeight: '400',
            background: '#F8FDFF',
            position: 'relative',
          }}
        >
          <AppHeader theme={clientData?.theme} />
          {displayNotActive ? <NotActive /> : null}
          {!displayNotActive && (
            <div style={{ flex: '1', margin: '0', height: '100%' }}>
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <div>
                  {!clientConfigLoading && (
                    <>
                      <Outlet context={clientData.theme} />
                      {children}
                    </>
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default Layout;
