import React from 'react';
import { Container } from '@mui/material';

import Footer from '../components/Footer';
import PageTitle from '../components/PageTitle';

const NotActive = () => {
  return (
    <Container data-testid="not-active">
      <PageTitle
        heading={`Internal Mobility is not active`}
        description={`If you believe this is in error please contact your administrator or reach out to:`}
      />

      <a href="https://help.arena.io/hc/en-us/requests/new">Support @Arena </a>
      <Footer />
    </Container>
  );
};

export default NotActive;
