import React from 'react';
import { CircularProgress } from '@mui/material';

const FullPageSpinner = () => {
  // v2 may have animated loading image from mockups
  return (
    <div
      data-testid="full-page-spinner"
      style={{
        display: 'flex',
        justifyContent: 'center',
        position: 'absolute',
        top: 0,
        right: 0,
        alignItems: 'center',
        height: '100vh',
        width: '100vw',
        zIndex: '100',
        backgroundColor: 'rgba(10, 0, 0, 0.5)',
      }}
      id="full-page-spinner"
    >
      <CircularProgress size={100} />
    </div>
  );
};

export default FullPageSpinner;
