import React, { MouseEventHandler, ReactElement } from 'react';
import { Button } from '@mui/material';

import { defaultTheme } from '../../config';
import { Theme } from '../../types/talent-rerouting';
import { getTextColor } from '../../utils/color-chooser';

interface propType {
  theme?: Partial<Theme>;
  content: string | ReactElement;
  contrastTextColor?: string;
  disabled?: boolean;
  position?: 'left' | 'right' | 'unset';
  fullWidth?: boolean;
  style?: React.CSSProperties;
  onClickHandler?: MouseEventHandler<HTMLButtonElement>;
}

const ThemedButtonSolid = ({
  theme = {},
  onClickHandler,
  content: text,
  disabled,
  position = 'unset',
  fullWidth = false,
  style,
}: propType) => {
  const { primaryColor, contrastTextColor } = theme;
  const backgroundColor = primaryColor ?? defaultTheme.primaryColor;
  const textColor = contrastTextColor ?? getTextColor(backgroundColor);
  return (
    <Button
      fullWidth={fullWidth}
      style={{
        backgroundColor: disabled ? 'grey' : backgroundColor,
        color: disabled ? 'white' : textColor ?? 'white',
        float: position,
        textTransform: 'none',
        height: '40px',
        ...style,
      }}
      onClick={onClickHandler}
      disabled={disabled}
    >
      <span>{text}</span>
    </Button>
  );
};

export default ThemedButtonSolid;
