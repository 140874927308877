import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { FormGroup } from '@mui/material';

import { Theme } from '../../../types/talent-rerouting';
import DropdownInput, {
  dropdownOption,
} from '../../../components/form-components/DropdownInput';
import FooterButton from '../../../components/form-components/FooterButton';
import ThemedButtonSolid from '../../../components/form-components/ThemedButtonSolid';
import { notEmptyStringValidation } from '../../../components/form-components/validators';
import PageTitle from '../../../components/PageTitle';
import useInputHook from '../../../hooks/UseInputHook';

export interface JobFormValue {
  internalJobId?: string | null;
  internalLocationId?: string | null;
  isValid?: boolean;
}

export interface JobFormProps {
  jobOptions?: dropdownOption[];
  locationOptions?: dropdownOption[];
  jobDetails?: JobFormValue;
  setJobDetails?: Dispatch<SetStateAction<JobFormValue>>;
  onContinue?: () => void;
  onBackClick?: () => void;
  theme?: Theme;
}
const JobDetailsValues = ({
  jobOptions = [],
  locationOptions = [],
  jobDetails: initialValue = {},
  setJobDetails,
  onContinue,
  onBackClick,
  theme = {} as Theme,
}: JobFormProps) => {
  const [disabled, setDisabled] = useState(true);

  const [internalLocationId, setLocation, isLocationValid, locationError] =
    useInputHook('Job Location', initialValue.internalLocationId ?? '', [
      notEmptyStringValidation,
    ]);
  const [internalJobId, setJobTitle, isTitleValid, titleError] = useInputHook(
    'Job Title',
    initialValue.internalJobId ?? '',
    [notEmptyStringValidation],
  );

  useEffect(() => {
    if (
      setJobDetails &&
      (internalLocationId !== initialValue.internalLocationId ||
        internalJobId !== initialValue.internalJobId)
    ) {
      setJobDetails({
        internalLocationId: internalLocationId,
        internalJobId: internalJobId,
        isValid: !disabled,
      });
    }
  }, [internalLocationId, internalJobId]);

  useEffect(() => {
    if (
      isLocationValid &&
      isTitleValid &&
      internalLocationId !== '' &&
      internalJobId !== ''
    ) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [isLocationValid, isTitleValid, internalLocationId, internalJobId]);

  const handleContinue = async () => {
    if (setJobDetails) {
      setJobDetails({ internalLocationId, internalJobId, isValid: !disabled });
    }
    if (onContinue) {
      onContinue();
    }
  };
  const isTabletOrMobile = useMediaQuery({ minWidth: 1224 });

  return (
    <>
      <div style={{ margin: '16px' }}>
        <PageTitle heading=" Please tell us more about you so we can show you career path options." />
        <form>
          <FormGroup>
            <DropdownInput
              label="Job Location"
              options={locationOptions}
              setValue={setLocation}
              value={internalLocationId}
              error={locationError}
            />
            <DropdownInput
              label="Job Title"
              options={jobOptions}
              setValue={setJobTitle}
              value={internalJobId}
              error={titleError}
              style={{ marginTop: '16px', height: '40px' }}
            />
          </FormGroup>
        </form>
        {isTabletOrMobile && (
          <ThemedButtonSolid
            content={'Continue'}
            theme={theme}
            style={{ width: '100%' }}
            onClickHandler={handleContinue}
            disabled={disabled}
          />
        )}
      </div>
      {!isTabletOrMobile && (
        <FooterButton
          onClick={handleContinue}
          disabled={disabled}
          theme={theme}
          addBackButton={true}
          onBackClick={onBackClick}
        />
      )}
    </>
  );
};

export default JobDetailsValues;
