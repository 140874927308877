import React, { useContext } from 'react';
import { useParams } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';

import { createUser } from '../../api/auth0';
import { GlobalContext } from '../../context/Global';
import FooterButton from '../../components/form-components/FooterButton';
import PageTitle from '../../components/PageTitle';
import ThemedButtonSolid from '../../components/form-components/ThemedButtonSolid';
import TextInput from '../../components/form-components/TextInput';
import {
  auth0passwordValidation,
  emailRegexValidation,
  notEmptyStringValidation,
} from '../../components/form-components/validators';
import useInputHook from '../../hooks/UseInputHook';

export interface UserCreateFormValues {
  email?: string;
  password?: string;
}

const CreateUser = () => {
  const { clientData, isSubmitting, setIsSubmitting } =
    useContext(GlobalContext);
  const { theme } = clientData;
  const { client_lookup_name } = useParams();

  const [error, setError] = React.useState<string>('');

  const [email, setEmail, emailIsValid, emailError] = useInputHook(
    'Email',
    '',
    [notEmptyStringValidation, emailRegexValidation],
  );
  const [password, setPassword, passwordIsValid, passwordError] = useInputHook(
    'Password',
    '',
    [notEmptyStringValidation, auth0passwordValidation],
  );

  const refEmail = React.useRef<HTMLInputElement>(null);
  const refPassword = React.useRef<HTMLInputElement>(null);

  const isTabletOrMobile = useMediaQuery({ minWidth: 1224 });

  const onEnterKeyDownSubmit = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      if (emailIsValid && passwordIsValid) {
        onSubmit();
      }
    }
  };

  const onEnterKeyFocusChange =
    (ref) => (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key === 'Enter') {
        ref.current?.focus();
      }
    };

  const onSubmit = async () => {
    setIsSubmitting(true);
    setError;
    // call api to create user

    try {
      const response = await createUser(
        {
          email,
          password,
          clientLookupName: client_lookup_name,
        },
        client_lookup_name ?? '',
      );
      if (response.error) {
        if (
          response.error.statusCode === 400 &&
          response.error.code === 'invalid_signup'
        ) {
          setError('User with that email already exists');
        } else if (
          response.error.statusCode === 400 &&
          response.error.code === 'invalid_password'
        ) {
          setError(
            response.error?.policy
              ? `Password Policy: ${response.error.policy.split('*').join('\n\n')}`
              : 'Invalid password',
          );
        } else {
          setError(
            'Error creating user. Please try again.  If this problem persists, please contact support.',
          );
        }
      }
    } catch (e) {
      setError(
        'Error creating user. Please try again.  If this problem persists, please contact support.',
      );
    }
    setIsSubmitting(false);
  };

  const isDisabled =
    isSubmitting ||
    !emailIsValid ||
    !passwordIsValid ||
    email === '' ||
    password === '';

  return (
    <div style={{ width: '100%', maxWidth: '455px' }}>
      <div
        style={{
          padding: '16px',
        }}
      >
        <PageTitle heading="Create User" />
        <TextInput
          type="email"
          label="Email"
          value={email}
          onChange={setEmail}
          error={emailError}
          onKeyUp={onEnterKeyFocusChange(refPassword)}
          ref={refEmail}
        />
        <TextInput
          type="password"
          label="Password"
          value={password}
          onChange={setPassword}
          error={passwordError}
          onKeyUp={onEnterKeyDownSubmit}
          ref={refPassword}
        />
        {isTabletOrMobile && (
          <ThemedButtonSolid
            content={'Continue'}
            theme={theme}
            style={{ width: '100%' }}
            onClickHandler={onSubmit}
            disabled={isDisabled}
          />
        )}
      </div>

      {!isTabletOrMobile && (
        <FooterButton onClick={onSubmit} disabled={isDisabled} theme={theme} />
      )}
      <p style={{ color: 'red', fontWeight: '600', fontSize: '16px' }}>
        {error}
      </p>
    </div>
  );
};

export default CreateUser;
