import React from 'react';
import { FormControl, FormControlLabel } from '@mui/material';

interface StyledFormControlProps {
  showError: boolean;
  control: React.JSX.Element;
  label: string;
  labelStyle?: React.CSSProperties;
  labelPlacement?: 'start' | 'end' | 'top' | 'bottom';
}
const StyledFormControl = ({
  showError,
  control,
  label,
  labelPlacement = 'start',
  labelStyle = {},
}: StyledFormControlProps) => {
  const topLabelStyle = {
    color: '#232323',
    fontFamily: 'Avenir Next LT Pro',
    fontSize: '10px',
    fontWeight: '600',
    textAlign: 'left',
    width: '100%',
    ...labelStyle,
  };
  const startLabelStyle = {
    width: '150px',
    paddingBottom: '25px',
    paddingRight: '15px',
    textAlign: 'right',
    ...labelStyle,
  };
  const style = {
    start: startLabelStyle,
    top: topLabelStyle,
  };

  return (
    <FormControl
      data-testid="styled-form-control"
      error={showError}
      sx={{
        width: '100%',
        '& .MuiFormControl-root': {
          marginTop: '4px',
        },
      }}
    >
      <FormControlLabel
        data-testid="styled-form-control-label"
        labelPlacement={labelPlacement}
        sx={{
          margin: 0,
          '& .MuiTypography-root': style[labelPlacement],
        }}
        control={control}
        label={label}
      />
    </FormControl>
  );
};

export default StyledFormControl;
