import React from 'react';
import { useMediaQuery } from 'react-responsive';

import FooterButton from '../../../components/form-components/FooterButton';
import PageTitle from '../../../components/PageTitle';
import ThemedButtonSolid from '../../../components/form-components/ThemedButtonSolid';
import TextInput from '../../../components/form-components/TextInput';
import {
  notEmptyStringValidation,
  zipCodeValidation,
} from '../../../components/form-components/validators';
import useInputHook from '../../../hooks/UseInputHook';
import { Theme } from '../../../types/talent-rerouting';

export interface UserFormValues {
  firstName?: string;
  lastName?: string;
  zipCode?: string;
}

const UserDetails = ({
  userDetails,
  setUserDetails,
  onContinue,
  theme,
}: {
  userDetails: UserFormValues;
  setUserDetails: React.Dispatch<React.SetStateAction<UserFormValues>>;
  theme: Theme;
  onContinue: () => void;
}) => {
  const [firstName, setFirstName, firstNameIsValid, firstNameError] =
    useInputHook('First Name', userDetails?.firstName ?? '', [
      notEmptyStringValidation,
    ]);
  const [lastName, setLastName, lastNameIsValid, lastNameError] = useInputHook(
    'Last Name',
    userDetails?.lastName ?? '',
    [notEmptyStringValidation],
  );
  const [zipCode, setZipCode, zipCodeIsValid, zipCodeError] = useInputHook(
    'ZipCode',
    userDetails?.zipCode ?? '',
    [notEmptyStringValidation, zipCodeValidation],
  );

  const refLastName = React.useRef<HTMLInputElement>(null);
  const refZipCode = React.useRef<HTMLInputElement>(null);

  const handleContinue = () => {
    setUserDetails({ firstName, lastName, zipCode });
    onContinue();
  };
  const isTabletOrMobile = useMediaQuery({ minWidth: 1224 });

  const onEnterKeyDownSubmit = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      handleContinue();
    }
  };

  const onEnterKeyFocusChange =
    (ref) => (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key === 'Enter') {
        ref.current?.focus();
      }
    };

  const isDisabled =
    !firstNameIsValid ||
    !lastNameIsValid ||
    !zipCodeIsValid ||
    firstName === '' ||
    lastName === '' ||
    zipCode === '';

  return (
    <div style={{}}>
      <div
        style={{
          padding: '16px',
        }}
      >
        <PageTitle heading="Register User" />
        <TextInput
          type="text"
          label="First Name"
          value={firstName}
          onChange={setFirstName}
          error={firstNameError}
          onKeyUp={onEnterKeyFocusChange(refLastName)}
        />
        <TextInput
          type="text"
          label="Last Name"
          value={lastName}
          onChange={setLastName}
          error={lastNameError}
          onKeyUp={onEnterKeyFocusChange(refZipCode)}
          ref={refLastName}
        />
        <TextInput
          type="text"
          label="Zip Code"
          value={zipCode}
          onChange={setZipCode}
          error={zipCodeError}
          onKeyUp={onEnterKeyDownSubmit}
          ref={refZipCode}
        />

        {isTabletOrMobile && (
          <ThemedButtonSolid
            content={'Continue'}
            theme={theme}
            style={{ width: '100%' }}
            onClickHandler={handleContinue}
            disabled={isDisabled}
          />
        )}
      </div>

      {!isTabletOrMobile && (
        <FooterButton
          onClick={handleContinue}
          disabled={isDisabled}
          theme={theme}
        />
      )}
    </div>
  );
};

export default UserDetails;
