import React from 'react';
import { Navigate, useSearchParams } from 'react-router-dom';
import { Container } from '@mui/material';

import Header from '../components/ArenaHeader';

const PasswordResetResult = () => {
  const [searchParams] = useSearchParams();
  const message = searchParams.get('message');
  const success = searchParams.get('success');

  if (success === null || message === null) {
    return <Navigate to="/login" />;
  }

  return (
    <Container>
      <Header />
      <h1>Password Reset {success === 'true' ? 'Success' : 'Failed'}</h1>
      <p>{message}</p>
    </Container>
  );
};

export default PasswordResetResult;
