import React, { useContext } from 'react';
import { useParams } from 'react-router-dom';
import { Divider, Drawer } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';

import { GlobalContext } from '../../context/Global';
import { UserContext } from '../../context/User';
import { logoutUser } from '../../api/auth0';
import MenuOption from './MenuOption';

interface SlideOutProps {
  onSetOpen?: (state: boolean) => void;
  openState?: boolean;
}

const SlideOutMenu = ({ onSetOpen, openState = true }: SlideOutProps) => {
  const { client_lookup_name } = useParams();
  const { clientData } = useContext(GlobalContext);
  const { user, removeUserFromContext } = useContext(UserContext);
  const handleDrawerClose = () => {
    if (onSetOpen) {
      onSetOpen(false);
    }
  };
  const openInNewTab = (url: string) => {
    window.open(url, '_blank');
    handleDrawerClose();
  };

  const onFeedbackClick = () =>
    openInNewTab('https://help.arena.io/hc/en-us/requests/new');
  const onTermsOfServiceClick = () => openInNewTab('https://arena.io/terms');
  const onPrivacyPolicyClick = () => openInNewTab('https://arena.io/privacy');
  const onCareerSiteClick = () => openInNewTab(clientData.careerWebsiteLink);

  const handleLogOut = () => {
    logoutUser(client_lookup_name ?? '').then(() => {
      removeUserFromContext();
    });
  };

  return (
    <Drawer
      variant="temporary"
      anchor="right"
      open={openState}
      sx={{ '& .MuiPaper-root': { maxHeight: '100vh' } }}
    >
      <div style={{ minWidth: '343px' }}>
        <div style={{ marginLeft: '20px' }}>
          <div style={{ textAlign: 'right' }}>
            <MenuOption onClick={handleDrawerClose}>
              {<CloseIcon data-testid="close-menu" fontSize="small" />}{' '}
            </MenuOption>
          </div>
          <Divider sx={{ marginLeft: '0' }} />
          <div style={{ textAlign: 'left' }}>
            {clientData?.careerWebsiteLink && (
              <MenuOption label="Career Site" onClick={onCareerSiteClick}>
                <OpenInNewIcon
                  fontSize="small"
                  data-testid="career-site"
                  sx={{ marginLeft: '8px' }}
                />
              </MenuOption>
            )}
            <MenuOption label="Product Feedback" onClick={onFeedbackClick} />
            <Divider sx={{ marginLeft: '0' }} />
            {user && <MenuOption label="Log Out" onClick={handleLogOut} />}
          </div>
          <div style={{ position: 'absolute', bottom: 0 }}>
            <MenuOption
              label="Arena Terms of Service"
              data-testid="terms-of-service"
              onClick={onTermsOfServiceClick}
            />
            <MenuOption
              label="Arena Privacy Policy"
              data-testid="privacy-policy"
              onClick={onPrivacyPolicyClick}
            />
          </div>
        </div>
      </div>
    </Drawer>
  );
};

export default SlideOutMenu;
