import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { registerUser } from '../../api/talent-rerouting';
import { getFacilities, getJobTitles } from '../../api/job-classifier';
import { GlobalContext } from '../../context/Global';
import { UserContext } from '../../context/User';
import { dropdownOption } from '../../components/form-components/DropdownInput';
import Footer from '../../components/Footer';
import FullPageSpinner from '../../components/FullPageSpinner';
import JobDetails, { JobFormValue } from './forms/JobDetailsForm';
import UserDetails, { UserFormValues } from './forms/UserDetailsForm';

const RegisterUser = () => {
  const { client_lookup_name } = useParams();
  const navigate = useNavigate();
  const { clientData } = useContext(GlobalContext);
  const { authObject, setLoggedInUser, user } = useContext(UserContext);
  const { theme } = clientData;

  const [locations, setLocations] = useState<dropdownOption[]>([]);
  const [isLoadingLocations, setIsLoadingLocations] = useState(true);

  const [jobTitles, setJobTitles] = useState<dropdownOption[]>([]);
  const [isLoadingJobTitles, setIsLoadingJobTitles] = useState(true);

  const [page, setPage] = React.useState<number>(0);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = React.useState<string>('');

  const [userDetails, setUserDetails] = React.useState<UserFormValues>({
    firstName: user?.firstName,
    lastName: user?.lastName,
    zipCode: user?.zipCode,
  });
  const [jobDetails, setJobDetails] = React.useState<JobFormValue>({
    internalLocationId: user?.internalLocationId,
    internalJobId: user?.internalJobId,
  });

  const getLocations = useCallback(async () => {
    if (client_lookup_name) {
      const locations = await getFacilities(client_lookup_name);
      setLocations(locations);
      setIsLoadingLocations(false);
    }
  }, [client_lookup_name]);

  const getJobs = useCallback(async () => {
    if (client_lookup_name) {
      const jobs = await getJobTitles(client_lookup_name);
      setJobTitles(jobs);
      setIsLoadingJobTitles(false);
    }
  }, [client_lookup_name]);

  useEffect(() => {
    if (client_lookup_name) {
      getLocations();
      getJobs();
    }
  }, [client_lookup_name, getJobs, getLocations]);

  const onSetUserDetails = (userDetails: UserFormValues) => {
    setUserDetails(userDetails);
    setError('');
  };

  const onSubmit = async () => {
    setIsSubmitting(true);
    try {
      const response = await registerUser(
        authObject,
        client_lookup_name ?? '',
        {
          ...userDetails,
          ...jobDetails,
          clientLookupName: client_lookup_name,
        },
      );
      if (response.error) {
        setError(response.error.message);
      } else {
        setLoggedInUser(response.user);
        if (response.user.needsScreening) {
          navigate(`/client/${client_lookup_name}/questionnaire`);
        } else {
          navigate(`/client/${client_lookup_name}`);
        }
      }
    } catch (e) {
      setError(
        'Error creating user. Please try again.  If this problem persists, please contact support.',
      );
    }

    setIsSubmitting(false);
  };

  return (
    <div style={{ width: '100%', maxWidth: '600px' }}>
      {page === 0 && (
        <UserDetails
          theme={theme}
          userDetails={userDetails}
          setUserDetails={onSetUserDetails}
          onContinue={() => setPage(1)}
        />
      )}
      {((page === 1 && (isLoadingJobTitles || isLoadingLocations)) ||
        isSubmitting) && <FullPageSpinner />}
      {page === 1 && !isLoadingJobTitles && !isLoadingLocations && (
        <JobDetails
          theme={theme}
          jobOptions={jobTitles}
          locationOptions={locations}
          jobDetails={jobDetails}
          setJobDetails={setJobDetails}
          onContinue={onSubmit}
          onBackClick={() => setPage(0)}
        />
      )}
      <Footer />
      <div style={{ color: 'red', fontWeight: '600', fontSize: '16px' }}>
        {error}
      </div>
    </div>
  );
};

export default RegisterUser;
