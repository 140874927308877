import React, { useContext, useState } from 'react';

import { UserContext } from '../../context/User';
import Footer from '../../components/Footer';
import PathCard from '../../components/PathCard';
import { Path, Theme } from '../../types/talent-rerouting';

const CareerPaths = ({
  careerPaths = [],
  onSearchClick,
  theme,
}: {
  careerPaths: Path[];
  onSearchClick: (jobId: string) => void;
  theme?: Theme;
}) => {
  const [expanded, setExpanded] = useState('');
  const handleExpansion = (id: string) =>
    setExpanded(expanded === id ? '' : id);
  const { user } = useContext(UserContext);
  return (
    <div style={{ maxWidth: '100%' }}>
      <div style={{ margin: '24px' }}>
        <span style={{ fontWeight: '600' }}>
          {careerPaths.length} available path
          {careerPaths.length !== 1 ? 's' : ''},
        </span>
        {careerPaths.length > 0 && <span> select your Career Path below.</span>}
      </div>
      {careerPaths.map((careerPath) => (
        <PathCard
          key={careerPath.careerPathId}
          path={careerPath}
          userJobONet={user?.jobOnetSocCode ?? undefined}
          onChange={handleExpansion}
          theme={theme ?? {}}
          expanded={expanded === careerPath.careerPathId}
          onSearchClick={onSearchClick}
        />
      ))}
      <Footer />
    </div>
  );
};

export default CareerPaths;
