import React from 'react';

import { Theme } from '../../types/talent-rerouting';
import ThemedButtonSolid from './ThemedButtonSolid';

const FooterButton = ({
  text = 'Continue',
  onClick,
  disabled = false,
  theme,
  addBackButton = false,
  onBackClick,
}: {
  onClick: () => void;
  disabled?: boolean;
  theme?: Theme;
  text?: string;
  addBackButton?: boolean;
  onBackClick?: () => void;
}) => {
  // the z index is set to 10 to overlap the normal footer in the layout to enable the button to be at the bottom of the page
  return (
    <div
      style={{
        position: 'absolute',
        bottom: 0,
        left: 0,
        width: '100%',
        zIndex: 10,
      }}
    >
      <div
        style={{
          backgroundColor: 'white',
          borderTop: '1px solid #E9E9E9',
          padding: '12px',
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <div>
          {addBackButton && (
            <ThemedButtonSolid
              onClickHandler={onBackClick}
              content="Back"
              theme={theme}
              style={{ height: '32px', width: '118px' }}
            />
          )}
        </div>
        <ThemedButtonSolid
          onClickHandler={onClick}
          content={text}
          theme={theme}
          style={{ height: '32px', width: '118px' }}
          disabled={disabled}
        />
      </div>
    </div>
  );
};

export default FooterButton;
