import React from 'react';
import { Avatar, Button } from '@mui/material';

import { Theme } from '../../types/talent-rerouting';
import { defaultTheme } from '../../config';

const JobSectionNavigationButton = ({
  label,
  count,
  onClick,
  focused,
  theme,
}: {
  label: string;
  count: number;
  onClick?: () => void;
  focused: boolean;
  theme?: Theme;
}) => {
  const backgroundColor = theme?.primaryColor ?? defaultTheme.primaryColor;

  return (
    <>
      {count > 0 && (
        <Button
          data-testid={`job-section-${label}`}
          style={{
            textTransform: 'none',
            background: focused ? `${backgroundColor}40` : '#FFF',
            color: 'black',
            borderRadius: 50,
            fontSize: '14px',
            fontWeight: '600',
            fontFamily: 'Avenir Next LT Pro',
            flex: 1,
          }}
          className={`job-section-navigation-button-${focused ? 'focused' : 'unfocused'}`}
          fullWidth
          onClick={onClick}
        >
          {label}
          <Avatar
            sx={{
              height: '18px',
              width: `${count.toString().length * 8 + 8}px`,
              fontSize: '12px',
              background: 'black',
              marginLeft: '8px',
              borderRadius: '10px',
            }}
          >
            {count}
          </Avatar>
        </Button>
      )}
    </>
  );
};

export default JobSectionNavigationButton;
