import React from 'react';
import { IconButton } from '@mui/material';

const textStyle = {
  color: '#232323',
  fontFamily: 'Avenir Next LT Pro',
  fontSize: '14px',
  fontStyle: 'normal',
  fontWeight: '600',
  lineHeight: 'normal',
};

export interface OptionProps extends React.PropsWithChildren {
  label?: string;
  onClick?: () => void;
}

const MenuOption = ({ label, onClick, children }: OptionProps) => {
  return (
    <div style={{ width: '100%' }}>
      <IconButton onClick={onClick} sx={{ paddingLeft: 0, margin: '6px 0' }}>
        <span style={textStyle}>{label}</span>
        {children}
      </IconButton>
    </div>
  );
};

export default MenuOption;
