import React, { useContext, useState } from 'react';

import { removeJobFromUser } from '../api/talent-rerouting';
import { GlobalContext } from '../context/Global';
import { UserContext } from '../context/User';
import Footer from '../components/Footer';
import JobCard from '../components/JobCard';
import PageTitle from '../components/PageTitle';

const Profile = () => {
  const { user, setUserJobs, userJobs, authObject } = useContext(UserContext);
  const { clientData } = useContext(GlobalContext);
  const theme = clientData?.theme;
  const [expanded, setExpanded] = useState('0');

  const handleExpansion = (id: string) =>
    setExpanded(expanded === id ? '0' : id);
  const handleRemoveClick = (userId: string, jobId: string) => {
    return () =>
      removeJobFromUser(user.id, jobId, authObject.accessToken, setUserJobs);
  };

  return (
    <div
      style={{
        width: '100vw',
        maxWidth: '600px',
        display: 'flex',
        flexDirection: 'column',
        paddingBottom: '50px',
      }}
    >
      <div style={{ padding: '16px' }}>
        <PageTitle
          heading="Stretch"
          description="Jobs that require additional training/certification."
          titleStyle={{ marginBottom: '8px' }}
          descriptionStyle={{ marginTop: 0 }}
        />
        <div>
          {userJobs.map((job) => (
            <JobCard
              job={job.jobDetails}
              key={job.id}
              expanded={expanded === job.id}
              onChange={() => handleExpansion(job.id)}
              onSaveClick={handleRemoveClick(user.id, job.id)}
              savedJob={true}
              theme={theme}
            />
          ))}
        </div>
      </div>

      <Footer
        style={{ marginTop: '50px', position: 'absolute', bottom: '0' }}
      />
    </div>
  );
};

export default Profile;
