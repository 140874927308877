import React from 'react';
import { Chip } from '@mui/material';

const CurrentPathChip = ({ style }: { style?: React.CSSProperties }) => (
  <Chip
    size="small"
    label="Current Path"
    data-testid="path-chip"
    sx={{
      height: '20px',
      backgroundColor: 'black',
      fontFamily: 'Avenir Next LT Pro',
      fontSize: '12px',
      fontWeight: '600',
      color: 'white',
      ...style,
    }}
  />
);

export default CurrentPathChip;
