import React from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from '@mui/material';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { defaultTheme } from '../config';
import { JobSearchJob, Theme } from '../types/talent-rerouting';
import ThemedButtonOutlined from './form-components/ThemedButtonOutlined';

const detailLabelStyle = {
  fontSize: '12px',
  fontWeight: '700',
};

const JobCard = ({
  job,
  theme,
  onChange,
  expanded,
  onSaveClick,
  isSameOrSimilarJob = false,
  savedJob = false,
}: {
  job: JobSearchJob;
  theme?: Theme;
  expanded: boolean;
  onChange: (expanded: number) => void;
  onSaveClick: () => void;
  isSameOrSimilarJob?: boolean;
  savedJob?: boolean;
}) => {
  const borderColor = theme?.primaryColor ?? defaultTheme.primaryColor;
  const nonSavedMessage = isSameOrSimilarJob ? 'Apply' : 'Save To Profile';
  const buttonText = savedJob ? 'Remove' : nonSavedMessage;

  return (
    <Accordion
      key={`job-card-accordion-${job.job_posting_id}`}
      disableGutters
      data-testid="job-card-accordion"
      sx={{
        marginBottom: '16px',
        borderRadius: '8px',
        borderTop: 0,
        '&&.MuiPaper-root.MuiAccordion-root ': {
          borderRadius: '8px',
        },
        '&&.MuiPaper-root.MuiAccordion-root:: before ': {
          backgroundColor: 'transparent',
        },
        '&&.MuiPaper-root.MuiAccordion-root.Mui-expanded ': {
          outline: `solid 4px ${borderColor}`,
        },
      }}
      onChange={() => onChange(job.job_posting_id)}
      expanded={expanded}
    >
      <AccordionSummary
        data-testid="job-card-summary"
        sx={{
          width: '100%',
          padding: '0',
          margin: '0',
          '& .MuiAccordionSummary-content': {
            margin: '0',
            padding: '0',
          },
        }}
      >
        <div style={{ width: '100%' }}>
          <div
            style={{
              padding: '16px',
              textAlign: 'left',
              color: theme?.primaryColor ?? defaultTheme.primaryColor,
              fontWeight: '600',
            }}
          >
            {job.display_label}
          </div>
          <div
            style={{
              background: '#F5F5F5',
              color: '#231f20',
              fontSize: '12px',
              fontWeight: '500',
              display: 'flex',
              padding: '8px 16px',
              flexDirection: 'column',
              alignItems: 'flex-start',
            }}
          >
            <div>
              <span style={detailLabelStyle}>Requisition-ID</span>
              <span style={{ padding: '0 5px' }}>
                {job.external_requisition_id}
              </span>
            </div>
            <div style={{ display: 'flex' }}>
              {job.employment_type && (
                <>
                  <span style={detailLabelStyle}>Job Type</span>
                  <span style={{ padding: '0 5px' }} data-testid="type-data">
                    {job.employment_type}
                  </span>
                </>
              )}
              {job.facility?.location && (
                <span>
                  <LocationOnOutlinedIcon
                    sx={{ fontSize: '14px', marginBottom: '-2px' }}
                  />
                  <span
                    style={{ padding: '0 2px' }}
                    data-testid="location-data"
                  >
                    {job.facility.location.state}-{job.facility.location.city}
                  </span>
                </span>
              )}
            </div>
          </div>
        </div>
      </AccordionSummary>
      <AccordionDetails data-testid="accordion-details" sx={{ padding: '0' }}>
        {job.description && (
          <div
            style={{
              margin: '16px',
            }}
          >
            <Typography
              data-testid="job-description"
              sx={{
                overflow: 'auto',
                fontFamily: 'Avenir Next LT Pro',
                fontSize: '14px',
                display: '-webkit-box',
                WebkitBoxOrient: 'vertical',
                whiteSpace: 'pre-wrap',
                textAlign: 'left',
                maxHeight: '40vh',
              }}
              component={'div'}
            >
              {job.description}
            </Typography>
          </div>
        )}
        <ThemedButtonOutlined theme={theme} onClick={() => onSaveClick()}>
          <>
            {buttonText}
            {isSameOrSimilarJob && (
              <OpenInNewIcon fontSize="small" sx={{ marginLeft: '8px' }} />
            )}
          </>
        </ThemedButtonOutlined>
      </AccordionDetails>
    </Accordion>
  );
};
export default JobCard;
