import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Box, Tabs, Tab } from '@mui/material';

import { getClientCareerPaths, getJobSearch } from '../api/talent-rerouting';
import { GlobalContext } from '../context/Global';
import { UserContext } from '../context/User';
import CurrentPathChip from '../components/CurrentPathChip';
import { dropdownOption } from '../components/form-components/DropdownInput';
import FullPageSpinner from '../components/FullPageSpinner';
import CareerPaths from './career-paths/CareerPaths';
import JobSearch from './job-search/JobSearch';
import Profile from './Profile';
import { JobSearchDataResults, Path } from '../types/talent-rerouting';

export type Section = {
  label: string;
  id: number;
  component?: React.JSX.Element;
  isLoading?: boolean;
};

const nullOption = { label: 'All', value: 'all' };

const Home = () => {
  const { user, userJobs, authObject, sessionIsLoading } =
    useContext(UserContext);
  const { accessToken } = authObject ?? {};
  const { clientData, clientConfigLoading } = useContext(GlobalContext);
  const { client_lookup_name } = useParams();

  const { theme } = clientData;
  const [careerPaths, setCareerPaths] = useState<Path[]>([]);
  const [dropdownOptions, setDropdownOptions] = useState<dropdownOption[]>([]);
  const [tabSelected, setTabSelected] = useState(0);

  const [pathsLoading, setPathsLoading] = useState(true);
  const [selectedPath, setSelectedPth] = useState<string>('all');

  const [jobsLoading, setJobsLoading] = useState(true);
  const [jobs, setJobs] = useState<JobSearchDataResults>({
    same: [],
    similar: [],
    stretch: [],
  });

  React.useEffect(() => {
    const pathOptions = careerPaths.map((path) => ({
      label: path.title
        ? path.title
        : path.description
          ? path.description
          : 'Career Path',
      value: path.careerPathId,
      optionalElement: path.onetCodes?.some((code) =>
        (user?.jobOnetSocCode ?? '').startsWith(code),
      ) ? (
        <CurrentPathChip style={{ marginLeft: '16px' }} />
      ) : undefined,
    }));
    setDropdownOptions([nullOption, ...pathOptions]);
  }, [careerPaths, user?.jobOnetSocCode]);

  useEffect(() => {
    const getData = async () => {
      const paths = await getClientCareerPaths(
        client_lookup_name ?? 'arena',
        accessToken,
      );

      paths.forEach((path) => {
        path.keyedDetails =
          path.detailList?.map((detail, index) => {
            return { key: `${path.careerPathId}-${index}`, value: detail };
          }) ?? [];
      });

      paths.sort((a, b) => {
        const aTitle = a.title ? a.title.trim().toLowerCase() : '';
        const bTitle = b.title ? b.title.trim().toLowerCase() : '';
        let result = 0;
        if (aTitle && bTitle && aTitle.length > 0 && bTitle.length > 0) {
          result = aTitle > bTitle ? 1 : -1;
        } else if (aTitle && aTitle.length > 0) {
          result = -1;
        } else if (bTitle && bTitle.length > 0) {
          result = 1;
        }
        return result;
      });
      setCareerPaths(paths);
      setPathsLoading(false);
    };

    const getJobs = async () => {
      if (user?.id && client_lookup_name && user?.jobOnetSocCode) {
        // onetSocCode is needed for job search
        try {
          const jobsResult = await getJobSearch(user?.id, accessToken);
          const { same, similar, stretch } = jobsResult;
          if (jobsResult && same && similar && stretch) {
            setJobs(jobsResult);
          } else {
            setJobs({
              same: [],
              similar: [],
              stretch: [],
            });
          }
        } catch (e) {
          console.error(e);
          // original state is empty arrays and will be handled appropriately
        }
      }
      setJobsLoading(false);
    };
    if (accessToken) {
      setJobsLoading(true);
      getJobs();
      setPathsLoading(true);
      getData();
    }
  }, [client_lookup_name, user, accessToken]);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabSelected(newValue);
  };

  const onSearchClickHandler = (jobId: string) => {
    setSelectedPth(jobId);
    setTabSelected(1);
  };

  const sections: Section[] = [
    {
      label: 'Career Paths',
      id: 1,
      component: (
        <CareerPaths
          careerPaths={careerPaths}
          onSearchClick={onSearchClickHandler}
          theme={theme}
        />
      ),
      isLoading: pathsLoading,
    },
    {
      label: 'Job Search',
      id: 2,
      component: (
        <JobSearch
          selectedPath={selectedPath}
          setSelectedPath={setSelectedPth}
          jobs={jobs}
          dropdownOptions={dropdownOptions}
          careerPaths={careerPaths}
          theme={theme}
          savedJobs={userJobs ?? []}
        />
      ),
      isLoading: jobsLoading,
    },
    { label: 'Profile', id: 3, component: <Profile /> },
  ];

  return (
    <div
      style={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <Box
        sx={{
          borderBottom: 1,
          borderColor: 'divider',
          position: 'sticky',
          top: 0,
          zIndex: 10,
          background: 'white',
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'wrap',
          justifyContent: 'center',
        }}
      >
        <Tabs
          centered
          variant="fullWidth"
          value={tabSelected}
          onChange={handleChange}
          aria-label="basic tabs example"
          TabIndicatorProps={{
            style: {
              background: theme?.primaryColor ?? 'black',
            },
          }}
          sx={{
            '& .MuiTab-root': {
              color: '#7B7B7B',
              fontFamily: 'Avenir Next LT Pro',
              fontSize: '14px',
              fontWeight: '600',
              width: '114px',
              padding: '0',
            },

            '&& .Mui-selected': {
              color: '#232323',
              fontFamily: 'Avenir Next LT Pro',
              fontSize: '16px',
              fontWeight: '600',
            },
            width: '100vw',
            maxWidth: '600px',
          }}
        >
          {sections.map((section: Section) => (
            <Tab
              key={`key-${section.id}`}
              label={section.label}
              id={`tab-${section.id}`}
              aria-controls={`tab-${section.id}`}
              sx={{
                textTransform: 'none',
                fontWeight: '600',
                fontSize: '16px',
              }}
            ></Tab>
          ))}
        </Tabs>
      </Box>
      <div>
        {sections.map((section: Section, index: number) => (
          <div key={section.id}>
            {tabSelected === index &&
              !section.isLoading &&
              !sessionIsLoading &&
              !clientConfigLoading && (
                <div>
                  {section.component
                    ? section.component
                    : `${section.label} page container`}{' '}
                </div>
              )}
            {tabSelected === index &&
              section.isLoading &&
              !sessionIsLoading &&
              !clientConfigLoading && <FullPageSpinner />}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Home;
