import React, { createContext, useState } from 'react';
import { ClientDataResponse, Theme } from '../types/talent-rerouting';

export interface GlobalContextData {
  isSubmitting: boolean;
  setIsSubmitting: (isSubmitting: boolean) => void;
  isLoading: boolean;
  setIsLoading: (isLoading: boolean) => void;
  clientConfigLoading: boolean;
  setClientConfigLoading: (clientConfigLoading: boolean) => void;
  clientData: ClientDataResponse;
  setClientData: (clientData: ClientDataResponse) => void;
}

// initial state
export const GlobalContext = createContext<GlobalContextData>({
  isSubmitting: false,
  setIsSubmitting: () => {},
  isLoading: false,
  setIsLoading: () => {},
  setClientData: () => {},
  clientData: { theme: {} as Theme },
  clientConfigLoading: true,
  setClientConfigLoading: () => {},
});

const GlobalContextProvider = ({ children }) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [clientData, setClientData] = useState<ClientDataResponse>({});
  const [clientConfigLoading, setClientConfigLoading] = useState(true);

  return (
    <GlobalContext.Provider
      value={{
        isSubmitting,
        setIsSubmitting,
        isLoading,
        setIsLoading,
        clientConfigLoading,
        setClientConfigLoading,
        clientData,
        setClientData,
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};

export default GlobalContextProvider;
