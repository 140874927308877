import React, { useContext, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { loginUser } from '../api/auth0';
import { GlobalContext } from '../context/Global';
import Footer from '../components/Footer';
import StyledLink from '../components/form-components/StyledLink';
import TextInput from '../components/form-components/TextInput';
import ThemedButtonSolid from '../components/form-components/ThemedButtonSolid';
import { notEmptyStringValidation } from '../components/form-components/validators';
import PageTitle from '../components/PageTitle';
import useInputHook from '../hooks/UseInputHook';

const Login = () => {
  const { clientData, setIsSubmitting } = useContext(GlobalContext);
  const { theme } = clientData;
  const { client_lookup_name } = useParams();
  const [loginError, setLoginError] = React.useState(' ');
  const ref = React.useRef<HTMLInputElement>(null);
  const navigate = useNavigate();

  const [email, setEmail, emailIsValid, emailError] = useInputHook(
    'Email',
    '',
    [notEmptyStringValidation],
  );
  const [password, setPassword, passwordIsValid, passwordError] = useInputHook(
    'Password',
    '',
    [notEmptyStringValidation],
  );

  const onLoginClick = async () => {
    setIsSubmitting(true);
    const response = await loginUser(email, password, client_lookup_name ?? '');
    if (response.error) {
      setLoginError('Login failed : ' + response.error.error_description);
    }
    setIsSubmitting(false);
  };

  const onEnterKeyDownSubmit = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      if (emailIsValid && passwordIsValid) {
        onLoginClick();
      }
    }
  };

  const onEnterKeyFocusChange = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      ref.current?.focus();
    }
  };

  useEffect(() => {
    setLoginError(' ');
  }, [email, password, setLoginError]);

  const LoginImageUrl = clientData?.loginConfig?.LoginImageUrl
    ? clientData.loginConfig.LoginImageUrl
    : '/mock-image/sample_loading_image.png';

  return (
    <div style={{ margin: '24px' }}>
      <div style={{ maxHeight: '215px' }}>
        <img
          data-testid="login-image"
          src={LoginImageUrl}
          alt="landing"
          style={{
            objectPosition: 'center',
            objectFit: 'cover',
            maxHeight: '200px',
            width: '100%',
          }}
        />
      </div>
      <PageTitle
        heading={clientData.loginConfig?.LoginTitle}
        description={clientData.loginConfig?.LoginSubTitle}
        style={{ margin: '16px 0' }}
      ></PageTitle>
      <form>
        <TextInput
          type="email"
          label="Email"
          value={email}
          onChange={setEmail}
          focusColor={theme?.primaryColor}
          error={emailError}
          onKeyUp={onEnterKeyFocusChange}
        />
        <TextInput
          ref={ref}
          type="password"
          label="Password"
          value={password}
          onChange={setPassword}
          focusColor={theme?.primaryColor}
          error={passwordError}
          onKeyUp={onEnterKeyDownSubmit}
        />
      </form>
      <div style={{ marginTop: '5px' }}>
        <ThemedButtonSolid
          content="Log In"
          fullWidth={true}
          theme={theme}
          onClickHandler={onLoginClick}
          disabled={
            !emailIsValid || !passwordIsValid || email === '' || password === ''
          }
        />
      </div>
      {loginError && (
        <div style={{ color: 'red', textAlign: 'center', padding: '8px' }}>
          {loginError}
        </div>
      )}
      <div style={{ textAlign: 'center' }}>
        {client_lookup_name !== 'arena' && client_lookup_name !== 'null' && (
          <StyledLink theme={theme} onClickHandler={() => navigate('./create')}>
            No account? Sign up{' '}
          </StyledLink>
        )}

        <StyledLink theme={theme} onClickHandler={() => navigate('./reset')}>
          I forgot my password{' '}
        </StyledLink>
      </div>
      <Footer />
    </div>
  );
};

export default Login;
