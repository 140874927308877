import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { AppBar, Box, IconButton } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';

import ArenaLogo from '../../assets/arena-logo-grey.svg';
import { defaultTheme } from '../../config';
import { Theme } from '../../types/talent-rerouting';
import { getTextColor } from '../../utils/color-chooser';
import SlideOutMenu from './SlideOutMenu';

const AppHeader = ({ theme }: { theme?: Theme }) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const navigate = useNavigate();
  const { client_lookup_name } = useParams();
  const backgroundColor =
    theme?.backgroundColor ?? defaultTheme.backgroundColor;
  const logo = theme?.logoImageUrl ? theme.logoImageUrl : ArenaLogo;

  return (
    <nav style={{ zIndex: 1 }} data-testid="app-header">
      <SlideOutMenu
        data-testid="slide-out-menu"
        openState={menuOpen}
        onSetOpen={(state: boolean) => setMenuOpen(state)}
      />
      <Box sx={{ flexGrow: 1 }}>
        <AppBar
          position="static"
          elevation={0}
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            flexDirection: 'row',
            backgroundColor: backgroundColor,
            color: getTextColor(backgroundColor),
            height: '50px',
          }}
        >
          <div style={{ maxHeight: '30px' }}>
            <img
              data-testid="logo"
              src={logo}
              alt={client_lookup_name}
              style={{ height: '36px', padding: '8px', maxWidth: '100px' }}
              onClick={() => navigate(`/client/${client_lookup_name}`)}
            ></img>
          </div>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}
            onClick={() => setMenuOpen(true)}
          >
            <MenuIcon />
          </IconButton>
        </AppBar>
      </Box>
    </nav>
  );
};

export default AppHeader;
