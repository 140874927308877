import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import { Link } from '@mui/material';

import { GlobalContext } from '../context/Global';
import { changePasswordRequest } from '../api/auth0';
import FooterButton from '../components/form-components/FooterButton';
import PageTitle from '../components/PageTitle';
import TextInput from '../components/form-components/TextInput';
import ThemedButtonSolid from '../components/form-components/ThemedButtonSolid';
import {
  emailRegexValidation,
  notEmptyStringValidation,
} from '../components/form-components/validators';
import Footer from '../components/Footer';
import useInputHook from '../hooks/UseInputHook';

const PasswordReset = () => {
  const { clientData, setIsSubmitting } = useContext(GlobalContext);
  const { theme } = clientData;
  const navigate = useNavigate();
  const [submitComplete, setSubmitComplete] = useState(false);
  const [email, setEmail, emailIsValid, emailError] = useInputHook(
    'Email',
    '',
    [notEmptyStringValidation, emailRegexValidation],
  );

  const resetPassword = async (email: string) => {
    // call the API to reset the password
    await changePasswordRequest(email);
    setSubmitComplete(true);
    setIsSubmitting(false);
    return;
  };

  const submitHandler = () => {
    setIsSubmitting(true);
    resetPassword(email);
    // submit the form and inform the user that the password reset email has been sent on success
  };
  const isTabletOrMobile = useMediaQuery({ minWidth: 1224 });
  const description = submitComplete
    ? ''
    : 'Please enter your email to reset your password.';

  const onEnterKeyUpSubmit = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      if (emailIsValid) {
        submitHandler();
      }
    }
  };

  return (
    <>
      <div style={{ margin: '16px' }}>
        <PageTitle heading="Password Reset" description={description} />
        {!submitComplete && (
          <div>
            <TextInput
              type="email"
              label="Email"
              value={email}
              onChange={setEmail}
              onKeyUp={onEnterKeyUpSubmit}
              error={emailError}
              focusColor={theme?.primaryColor}
            />
          </div>
        )}
        {submitComplete && (
          <div>
            <p>
              Instructions have been sent to your email to reset your password.
            </p>
            <p>
              {' '}
              Once you have reset your password please{' '}
              <Link onClick={() => navigate('../')}>login</Link> again.
            </p>
          </div>
        )}
        {!submitComplete && isTabletOrMobile && (
          <ThemedButtonSolid
            content={'Continue'}
            theme={theme}
            style={{ width: '100%' }}
            onClickHandler={submitHandler}
            disabled={!emailIsValid || email === ''}
          />
        )}
      </div>

      {!submitComplete && !isTabletOrMobile && (
        <FooterButton
          onClick={submitHandler}
          disabled={!emailIsValid || email === ''}
          theme={theme}
        />
      )}
      <Footer />
    </>
  );
};

export default PasswordReset;
