import React, { useContext } from 'react';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { Container } from '@mui/material';

import { GlobalContext } from '../context/Global';
import { UserContext } from '../context/User';
import ThemedButtonSolid from '../components/form-components/ThemedButtonSolid';
import PageTitle from '../components/PageTitle';
import Footer from '../components/Footer';

const RetakeQuestionnaire = () => {
  const { user } = useContext(UserContext);
  const { clientData } = useContext(GlobalContext);

  const neverTakenQuestionnaireMessage =
    'You have never taken the Arena Questionnaire. Please click the button below to take the questionnaire';
  const neverTakenQuestionnaireTitle = 'Take the Arena Questionnaire';

  const longerThanSixMonthsMessage = `it has been longer than 6 months since you have taken the Arena Questionnaire. Please click the button below to retake the questionnaire and update your profile`;
  const longerThanSixMonthsTitle = 'Retake the Arena Questionnaire';

  const message = user?.screeningCompletedDate
    ? longerThanSixMonthsMessage
    : neverTakenQuestionnaireMessage;
  const title = user?.screeningCompletedDate
    ? longerThanSixMonthsTitle
    : neverTakenQuestionnaireTitle;

  return (
    <Container>
      <PageTitle heading={title} description={message} />
      <ThemedButtonSolid
        content={
          <>
            <span>Continue</span>
            <OpenInNewIcon
              fontSize="small"
              sx={{ marginLeft: '8px', marginBottom: '-4px' }}
            />
          </>
        }
        onClickHandler={() => window.open(user.questionnaireURL, '_blank')}
        theme={clientData?.theme}
      />
      <Footer />
    </Container>
  );
};

export default RetakeQuestionnaire;
