import {
  Dispatch,
  SetStateAction,
  useState,
  useCallback,
  useEffect,
} from 'react';

const useInputHook = (
  fieldName: string,
  fieldValue: string,
  validators?: ((value: string, fieldName: string) => string | undefined)[],
): [string, Dispatch<SetStateAction<string>>, boolean, string] => {
  const [error, setError] = useState<string>('');
  const [value, setValue] = useState<string>(fieldValue);
  const [isValid, setIsValid] = useState<boolean>(true);

  // will set first detected error to error state
  const validate = useCallback(() => {
    let error = '';
    if (!validators) {
      return error;
    }
    for (const validator of validators) {
      const detectedError = validator(value, fieldName);
      if (detectedError && detectedError.length > 0) {
        error = detectedError;
        setIsValid(false);
        break;
      }
      setIsValid(true);
    }
    setError(error);
  }, [validators, fieldName, value]);

  useEffect(() => {
    validate();
  }, [validate, value]);

  return [value, setValue, isValid, error];
};

export default useInputHook;
