import React from 'react';
import ArenaSVG from '../assets/arena-logo-grey.svg';

const Footer = ({ style }: { style?: React.CSSProperties }) => {
  return (
    <div
      data-testid="footer"
      style={{
        margin: '20px 0',
        bottom: '0',
        width: '100%',
        textAlign: 'center',
        position: 'relative',
        zIndex: 9,
        ...style,
      }}
    >
      <div
        style={{
          fontSize: '10px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          color: '#7B7B7B',
          fontWeight: '600',
        }}
      >
        {' '}
        <span>powered by </span>
        <img
          style={{ height: '10px', paddingLeft: '2px' }}
          src={ArenaSVG}
          alt={'Arena'}
        />
      </div>
    </div>
  );
};

export default Footer;
