import React from 'react';
import { useMediaQuery } from 'react-responsive';
import { Container } from '@mui/material';

import ArenaSVG from '../assets/arena-logo-grey.svg';

export interface HeaderProps {
  imageSrc?: string;
  customText?: string;
  backgroundColor?: string;
  contrastTextColor?: string;
  backgroundImage?: string;
  clientName?: string;
}

const Header = ({
  imageSrc = ArenaSVG,
  customText = '',
  backgroundColor = '#FFFFFF',
  contrastTextColor = '#000000',
  backgroundImage = '',
  clientName = 'Arena',
}: HeaderProps) => {
  const textWidth = `${customText.length * 3.5 || 100}px`;
  const mediaQueryWidth = `${customText.length * 3.5 + 275}px`;
  const mediaQueryCutOff = useMediaQuery({
    query: `(max-width: ${mediaQueryWidth})`,
  });

  return (
    <header
      className="header"
      data-testid="header"
      style={{
        backgroundColor: backgroundColor,
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        minHeight: '80px',
      }}
    >
      <Container
        className="header_container"
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          padding: '10px 40px',
        }}
      >
        <img
          data-testid="header_logo"
          src={imageSrc}
          alt={`${clientName} logo`}
          className="header_logo"
          style={{
            flexBasis: '100px',
            maxHeight: '100px',
            minHeight: '60px',
            maxWidth: '200px',
            flexShrink: 1,
            padding: '0 20px',
          }}
        />
        {!mediaQueryCutOff && (
          <h3
            data-testid="header_title"
            style={{
              color: contrastTextColor,
              textAlign: 'center',
              paddingRight: '20px',
              maxHeight: '100px',
              width: textWidth,
            }}
            className="header_title"
          >
            {customText}
          </h3>
        )}
      </Container>
    </header>
  );
};

export default Header;
