import React, { useEffect } from 'react';

const useOnScreen = (options): [React.RefObject<HTMLDivElement>, boolean] => {
  const containerRef = React.useRef<HTMLDivElement>(null);
  const [isVisible, setIsVisible] = React.useState(false);

  const callBackFunction = (entries: IntersectionObserverEntry[]) => {
    const [entry] = entries;
    setIsVisible(entry.isIntersecting);
  };

  useEffect(() => {
    const observer = new IntersectionObserver(callBackFunction, options);
    const current = containerRef.current;
    if (containerRef.current) observer.observe(containerRef.current);
    return () => {
      if (current) observer.unobserve(current);
    };
  }, [containerRef, options]);

  return [containerRef, isVisible];
};

export default useOnScreen;
