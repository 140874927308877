import axios from 'axios';
import { Auth0UserProfile } from 'auth0-js';

import { TR_API_URL } from '../config';
import { UserDetails, AuthResponse } from '../types/auth0';
import {
  ClickTrackingCreateRequest,
  ClickTrackingMetadata,
  ClientDataResponse,
  InternalMobilityConfig,
  JobSearchDataResults,
  JobSearchJob,
  Path,
  RawTheme,
  SavedJob,
  Theme,
  User,
} from '../types/talent-rerouting';

export const mapClickTrackingEvent = (
  user: User,
  jobMetadata: ClickTrackingMetadata,
): ClickTrackingCreateRequest => {
  const {
    id: internalMobilityUserId,
    clientLookupName,
    screeningUUID: screeningUuid,
    screeningUserId,
    internalJobId,
    jobOnetSocCode,
    internalLocationId,
  } = user;
  const { clickSource: type, url = null, ...metadata } = jobMetadata;

  return {
    internalMobilityUserId,
    clientLookupName,
    screeningUuid,
    screeningUserId,
    url,
    type,
    internalJobId,
    jobOnetSocCode,
    internalLocationId,
    metadata,
  };
};

const getAuthHeader = (token: string) => {
  return {
    headers: { Authorization: `Bearer ${token}` },
  };
};

export const getConfig = async (
  clientLookupName: string,
  successCallback?: (config: InternalMobilityConfig) => void,
  errorCallback?: (e: unknown) => void,
): Promise<InternalMobilityConfig> => {
  try {
    const url = `${TR_API_URL}/internal-mobility/${clientLookupName}/config`;
    const response = await axios.get<InternalMobilityConfig>(url);
    const themeResponse = { ...response.data.theme };
    // clear nulls
    const keys = Object.keys(themeResponse as object);
    const cleanedTheme: Partial<Theme> = {};
    keys.forEach((key) => {
      const value = themeResponse[key as keyof RawTheme];
      if (typeof value === 'string') {
        cleanedTheme[key as keyof RawTheme] = value;
      }
    });
    const config = {
      ...response.data,
      theme: cleanedTheme,
    } as InternalMobilityConfig;
    if (successCallback) {
      successCallback(config);
    }
    return config;
  } catch (error: unknown) {
    if (errorCallback) {
      errorCallback(error);
    }
    return {} as InternalMobilityConfig;
  }
};

export const getClientData = async (
  client_lookup_name: string,
  callback?: (response: ClientDataResponse) => void,
): Promise<ClientDataResponse> => {
  const response = await getConfig(client_lookup_name);

  if (callback) {
    callback(response);
  }
  return response;
};

// get client career paths data
export const getClientCareerPaths = async (
  client_lookup_name: string,
  token: string,
): Promise<Path[]> => {
  if (client_lookup_name === undefined) {
    throw 'client_lookup_name is required';
  }
  const url = `${TR_API_URL}/internal-mobility/${client_lookup_name}/paths`;
  const response = await axios.get<Path[]>(url, getAuthHeader(token));

  return response.data;
};

export const getUserDetails = async (
  auth0User: Auth0UserProfile,
  token: string,
  clientLookupName: string,
): Promise<User> => {
  try {
    const user = await axios.post(
      TR_API_URL + '/internal-mobility/user/login',
      auth0User,
      getAuthHeader(token),
    );

    return user.data;
  } catch (e) {
    if (e.response.status === 404) {
      return {
        authId: auth0User.sub,
        email: auth0User.email,
        screeningUUID: null,
        screeningUserId: null,
        id: null,
        savedJobs: [],
        screeningCompletedDate: null,
        clientLookupName,
        firstName: null,
        lastName: null,
        phone: null,
        zipCode: null,
        needRegistration: true,
      };
    }
  }
};

export const updateUserRegistration = async (
  userDetails: UserDetails,
  clientLookupName: string,
  authObject: AuthResponse,
): Promise<User> => {
  const { sub: authId, email } = authObject.idTokenPayload;
  const user = await axios.post<User>(
    `${TR_API_URL}/internal-mobility/user/registration`,
    {
      userDetails: { ...userDetails, email, authId },
      clientLookupName,
      authId,
    },
    getAuthHeader(authObject.accessToken),
  );
  return user.data;
};

export const addJobToUser = async (
  userId: string,
  job: JobSearchJob,
  token: string,
  callback?: (jobs: SavedJob[]) => void,
): Promise<JobSearchJob[]> => {
  const jobs = await axios.put(
    TR_API_URL + `/internal-mobility/user/${userId}/jobs`,
    job,
    getAuthHeader(token),
  );
  if (callback) {
    callback(jobs.data);
  }
  return jobs.data;
};

export const removeJobFromUser = async (
  userId: string,
  jobId: string,
  token: string,
  callback?: (jobs: SavedJob[]) => void,
): Promise<JobSearchJob[]> => {
  const jobs = await axios.delete(
    TR_API_URL + `/internal-mobility/user/${userId}/jobs/${jobId}`,
    getAuthHeader(token),
  );
  if (callback) {
    callback(jobs.data);
  }
  return jobs.data;
};

export const getJobSearch = async (
  // search for jobs based on user's job code
  userId: string,
  token: string,
): Promise<JobSearchDataResults> => {
  const url = TR_API_URL + `/internal-mobility/user/${userId}/search`;
  const response = await axios.get(url, getAuthHeader(token));
  return response.data;
};

export const registerUser = async (
  authObject: AuthResponse,
  client: string,
  userDetails: UserDetails,
) => {
  const { accessToken, idTokenPayload } = authObject;
  const { email, sub: authId } = idTokenPayload;
  if (userDetails === undefined || client === undefined) {
    throw 'userDetails and client are required';
  }

  try {
    const response = await axios.put<UserDetails>(
      TR_API_URL + '/internal-mobility/user',
      { email, authId, ...userDetails },
      getAuthHeader(accessToken),
    );
    return { error: null, user: response.data, authObject };
  } catch (e) {
    return { error: e, user: null, authObject: null };
  }
};

export const sendClickTrackingEvent = async (
  user: User,
  jobMetadata: ClickTrackingMetadata,
  token: string,
): Promise<string> => {
  const trackingEntry = mapClickTrackingEvent(user, jobMetadata);

  const trackingIdResponse = await axios.post<string>(
    `${TR_API_URL}/internal-mobility/click-tracking`,
    trackingEntry,
    getAuthHeader(token),
  );

  return trackingIdResponse.data;
};
