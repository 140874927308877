import React, { useCallback, useContext, useEffect } from 'react';
import {
  Navigate,
  NavigateFunction,
  NavigateProps,
  generatePath,
  useLocation,
  useNavigate,
  useSearchParams,
} from 'react-router-dom';
import {
  Auth0DecodedHash,
  Auth0Error,
  Auth0ParseHashError,
  Auth0UserProfile,
} from 'auth0-js';
import { getUserDetails } from '../api/talent-rerouting';
import { webAuth } from '../auth/auth0.service';
import { UserContext } from '../context/User';
import FullPageSpinner from '../components/FullPageSpinner';
import { User } from '../types/talent-rerouting';

interface Props extends NavigateProps {
  to: string;
}
// exported for testing. this is curried for dependency injection
// it returns a function that is used as a callback for the auth0 parseHash function
// which takes the authO parse error and result
export const parseHashCallback =
  (
    setLoggedInUser: { (user: User): void; (arg0: User): void },
    navigate: NavigateFunction,
    client_lookup_name: string,
  ) =>
  (err: Auth0ParseHashError | null, authResult: Auth0DecodedHash | null) => {
    if (err) {
      console.log(err);
      return;
    }

    if (authResult?.accessToken) {
      const { accessToken } = authResult;
      webAuth.client.userInfo(
        accessToken,
        async (err: Auth0Error | null, user: Auth0UserProfile) => {
          if (err) {
            console.log(err);
            return;
          }

          const userDetails = await getUserDetails(
            user,
            authResult?.accessToken,
            client_lookup_name,
          );
          if (userDetails) {
            setLoggedInUser(userDetails);
            if (userDetails.needRegistration) {
              navigate(
                `/client/${userDetails.clientLookupName ?? client_lookup_name}/register`,
              );
            } else if (userDetails.needsScreening) {
              navigate(
                `/client/${userDetails.clientLookupName ?? client_lookup_name}/questionnaire`,
              );
            } else {
              navigate(`/client/${userDetails.clientLookupName}`);
            }
          }
        },
      );
    } else {
      navigate(`/client/${client_lookup_name}/login`);
    }
  };

// this component is used for the authentication redirect from auth0
// it parses the hash to get the user data and sets the user in the AuthContext

export const NavigateWithParams: React.FC<Props> = ({ to, ...props }) => {
  const [searchParams] = useSearchParams();
  const { setLoggedInUser } = useContext(UserContext);
  const navigate = useNavigate();
  const client_lookup_name = searchParams.get('client');
  const location = useLocation();

  const processHash = useCallback(
    (hash: string) => {
      try {
        webAuth.parseHash(
          { hash },
          parseHashCallback(setLoggedInUser, navigate, client_lookup_name),
        );
      } catch (e) {
        if (client_lookup_name) {
          navigate(`/client/${client_lookup_name}/login`);
        } else {
          navigate('/404');
        }
      }
    },
    [client_lookup_name, navigate, setLoggedInUser],
  );

  useEffect(() => {
    processHash(location.hash);
  }, [location, processHash]);

  return (
    <div>
      <FullPageSpinner />
      <Navigate {...props} to={generatePath(to, { client_lookup_name })} />;
    </div>
  );
};
