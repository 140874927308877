import { Theme } from './types/talent-rerouting';

export const PORT = process.env.REACT_APP_PORT || 3000;
export const AUTH0_AUDIENCE = process.env.REACT_APP_AUTH0_AUDIENCE;
export const AUTH0_CLIENT_ID = process.env.REACT_APP_AUTH0_CLIENT_ID;
export const AUTH0_DOMAIN = process.env.REACT_APP_AUTH0_DOMAIN;
export const AUTH0_LOGIN_RESPONSE_TYPE =
  process.env.REACT_APP_AUTH0_LOGIN_RESPONSE_TYPE;
export const AUTH0_LOGOUT_URI = process.env.REACT_APP_AUTH0_LOGOUT_URI;
export const AUTH0_REALM = process.env.REACT_APP_AUTH0_REALM; // database connection name on auth0 configuration
export const AUTH0_REDIRECT_URI = process.env.REACT_APP_AUTH0_REDIRECT_URI;
export const AUTH0_USER_SCOPE = process.env.REACT_APP_AUTH0_USER_SCOPE;

export const JOB_CLASSIFIER_API_URL =
  process.env.REACT_APP_JOB_CLASSIFIER_API_URL;
export const PGD_ENVIRONMENT = process.env.REACT_APP_PGD_ENVIRONMENT;
export const TR_API_URL = process.env.REACT_APP_TR_API_URL;

export const defaultTheme: Partial<Theme> = {
  logoKey: 'arena-logo',
  primaryColor: '#082FA8',
  backgroundColor: '#09F887',
};
