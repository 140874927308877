import React from 'react';
import { Button } from '@mui/material';

import { defaultTheme } from '../../config';
import { Theme } from '../../types/talent-rerouting';

const ThemedButtonOutlined = ({
  theme,
  children,
  onClick,
  style,
}: {
  theme?: Theme;
  children?: React.ReactNode;
  onClick?: (e) => void;
  style?: React.CSSProperties;
}) => {
  const borderColor = theme?.primaryColor ?? defaultTheme.primaryColor;

  return (
    <Button
      data-testid="themed-button"
      onClick={onClick}
      sx={{
        margin: '24px',
        color: borderColor,
        border: `1px solid ${borderColor}`,
        textTransform: 'none',
        ...style,
      }}
      variant={'outlined'}
    >
      {children}
    </Button>
  );
};

export default ThemedButtonOutlined;
