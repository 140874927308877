import React from 'react';
import { Container } from '@mui/material';

import Header from '../components/ArenaHeader';

const PageNotFound = () => {
  return (
    <Container>
      <Header />
      <h1> 404 </h1>
      <h2> Page Not Found </h2>
      <p>The page you were directed to does not exist.</p>

      <p>
        If continue to reach this page, please contact Arena support at:{' '}
        <b>support@arena.io</b>
      </p>
    </Container>
  );
};
export default PageNotFound;
