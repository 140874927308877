import React from 'react';
import { Button } from '@mui/material';

import { Theme } from '../../types/talent-rerouting';
import { defaultTheme } from '../../config';

export interface StyledLinkProps {
  theme?: Theme;
  children: React.ReactNode;
  onClickHandler?: () => void;
}

const StyledLink = ({ theme, children, onClickHandler }: StyledLinkProps) => {
  return (
    <Button
      data-testid="styled-link"
      fullWidth
      style={{
        color: theme?.primaryColor ?? defaultTheme.primaryColor,
        textDecoration: 'none',
        textTransform: 'none',
        marginTop: '10px',
        fontWeight: '600',
        padding: '0',
        fontFamily: 'Avenir Next LT Pro',
      }}
      onClick={onClickHandler}
    >
      {children}
    </Button>
  );
};

export default StyledLink;
