import React from 'react';
import ReactDOM from 'react-dom/client';
import ReactGA from 'react-ga4';

import './index.css';
import App from './App';
import './index.css';
import { PGD_ENVIRONMENT } from './config';

const trackingId =
  PGD_ENVIRONMENT === 'production' ? 'G-JKEQWLSTTS' : 'G-B4TBS11362';

ReactGA.initialize(trackingId);
import './index.css';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
);
