import axios from 'axios';
import axiosRetry, { IAxiosRetryConfig } from 'axios-retry';

import { JOB_CLASSIFIER_API_URL } from '../config';
import {
  DropdownOption,
  FacilityLocations,
  JobTitles,
} from '../types/job-classifer';

const client = axios.create();
const config: IAxiosRetryConfig = {
  retries: 3,
};

// eslint-disable-next-line @typescript-eslint/no-unsafe-call
axiosRetry(client, config);

// some clients have duplicate job titles and facilities in the job classifier database,
// this function will remove duplicates keeping the first returned value
const deDuplicateArray = (arr1: DropdownOption[]): DropdownOption[] => {
  const seen = new Set();
  return arr1.filter((item) => {
    const duplicate = seen.has(item.label.toLowerCase());
    seen.add(item.label.toLowerCase());
    return !duplicate;
  });
};

const shortLabel = (label: string) =>
  label.length > 45 ? label.substring(0, 40) + '...' : label;

const mapJobData = (jobData: JobTitles[]): DropdownOption[] => {
  const jobOptions = jobData.map((job) => {
    return {
      value: job.id,
      label: shortLabel(job.jobTitle),
    };
  });
  return deDuplicateArray(jobOptions);
};

const mapFacilityData = (
  facilityData: FacilityLocations[],
): DropdownOption[] => {
  const facilityOptions = facilityData.map((facility) => {
    return {
      value: facility.id,
      label: shortLabel(facility.displayName),
    };
  });

  return deDuplicateArray(facilityOptions);
};

export const getJobTitles = async (
  clientLookupName: string,
  successCallback?: (data: DropdownOption[]) => void,
  errorCallback?: (e: unknown) => void,
): Promise<DropdownOption[]> => {
  try {
    const response = await client.get(
      `${JOB_CLASSIFIER_API_URL}/jobs?clientLookupName=${clientLookupName}`,
    );
    const dropdownOptions = mapJobData(response.data as JobTitles[]);
    if (successCallback) {
      successCallback(dropdownOptions);
    }
    return dropdownOptions;
  } catch (error: unknown) {
    if (errorCallback) {
      errorCallback(error);
    }
  }
  return [];
};

export const getFacilities = async (
  clientLookupName: string,
  successCallback?: (data: DropdownOption[]) => void,
  errorCallback?: (e: unknown) => void,
): Promise<DropdownOption[]> => {
  try {
    const response = await client.get(
      `${JOB_CLASSIFIER_API_URL}/facilities?clientLookupName=${clientLookupName}`,
    );
    const facilityOptions = mapFacilityData(
      response.data as FacilityLocations[],
    );
    if (successCallback) {
      successCallback(facilityOptions);
    }
    return facilityOptions;
  } catch (error: unknown) {
    if (errorCallback) {
      errorCallback(error);
    }
  }
  return [];
};
