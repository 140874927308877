import React, { useState } from 'react';
import { MenuItem, Select } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import StyledFormControl from './StyledFormControl';

export interface DropdownInputProps {
  error?: string;
  id?: string;
  value: string;
  label: string;
  options: dropdownOption[];
  setValue: (value: string) => void;
  showErrors?: boolean;
  style?: React.CSSProperties;
  isChangedDefault?: boolean;
}

export interface dropdownOption {
  value: string;
  label: string;
  optionalElement?: React.ReactElement;
}

const DropdownInput = ({
  error,
  value,
  label,
  options,
  setValue,
  showErrors = true,
  isChangedDefault = false,
  style,
}: DropdownInputProps) => {
  const [currentValue, setCurrentValue] = useState(value || '');
  const [isChanged, setIsChanged] = useState<boolean>(isChangedDefault);
  const [isFocused, setIsFocused] = useState<boolean>(false);

  const onChangeHandler = (event: { target: { value: string } }) => {
    const { value } = event.target;
    const selectedOption = value;
    setCurrentValue(selectedOption);
    if (setValue) {
      setValue(selectedOption);
    }
    setIsChanged(true);
  };

  const onFocusHandler = () => {
    setIsChanged(true);
    setIsFocused(true);
  };

  const onBlurHandler = () => setIsFocused(false);

  const showError = isChanged && !isFocused && Boolean(error);
  const testLabel = (label ?? '').toLowerCase().replace(/\s/g, '-');

  return (
    <StyledFormControl
      showError={showError}
      label={label}
      labelPlacement="top"
      control={
        <div style={{ width: '100%', textAlign: 'left' }}>
          <Select
            inputProps={{ 'data-testid': testLabel }}
            name={label}
            fullWidth={true}
            size="small"
            value={currentValue}
            error={showError}
            onChange={onChangeHandler}
            onFocus={onFocusHandler}
            onBlur={onBlurHandler}
            sx={{ fontSize: '14px', backgroundColor: 'white', ...style }}
            renderValue={(value) => {
              const option = options.find((o) => o.value === value);
              return (
                <div>
                  {option?.label} {option?.optionalElement}
                </div>
              );
            }}
            IconComponent={ExpandMoreIcon}
          >
            {options.map((option) => (
              <MenuItem
                key={option.value}
                value={option.value}
                sx={{ fontSize: '14px' }}
              >
                {option.label} {option.optionalElement}
              </MenuItem>
            ))}
          </Select>
          {showErrors && (
            <div // select component does not have helper text, so we are adding a div to show the error message
              style={{
                fontFamily: 'Avenir Next LT Pro',
                color: 'red',
                fontWeight: '600',
                fontSize: '12px',
                textAlign: 'left',
                padding: '5px 15px',
                height: '15px',
              }}
            >
              {showError ? error : ' '}
            </div>
          )}
        </div>
      }
    />
  );
};

export default DropdownInput;
