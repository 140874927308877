import React from 'react';
import { Routes, Route, BrowserRouter, Navigate } from 'react-router-dom';
import './App.css';
import UserContextProvider from './context/User';
import GlobalContextProvider from './context/Global';
import AuthGuard from './auth/AuthGuard';
import Home from './pages/Home';
import ThemedLayout from './pages/ThemedLayout';
import PageNotFound from './pages/PageNotFound';
import Login from './pages/Login';
import RegisterUser from './pages/register/RegisterUser';
import PasswordReset from './pages/PasswordReset';
import { NavigateWithParams } from './pages/NavigateWithParams';
import PasswordResetResult from './pages/PasswordResetResult';
import RetakeQuestionnaire from './pages/RetakeQuestionnaire';
import CreateUser from './pages/register/CreateUser';

function App() {
  return (
    <div className="App" style={{ minHeight: '100vh' }}>
      <BrowserRouter>
        <GlobalContextProvider>
          <UserContextProvider>
            <Routes>
              <Route
                path="/login"
                element={<Navigate replace to="/client/arena" />}
              />
              <Route
                path="/client/null/"
                element={<Navigate replace to="/client/arena" />}
              />
              <Route
                path="/client/:client_lookup_name"
                element={<ThemedLayout />}
              >
                <Route
                  path="questionnaire"
                  element={
                    <AuthGuard redirectPath="../">
                      <RetakeQuestionnaire />
                    </AuthGuard>
                  }
                />
                <Route
                  path="register"
                  element={
                    <AuthGuard redirectPath="../">
                      <RegisterUser />
                    </AuthGuard>
                  }
                />
                <Route path="reset" element={<PasswordReset />} />
                <Route
                  path="login"
                  element={
                    <AuthGuard shouldBeLoggedIn={false} redirectPath="../" />
                  }
                >
                  {/* these are used in the guard outlet tag */}
                  <Route index element={<Login />} />
                  <Route path="reset" element={<PasswordReset />} />
                  <Route path="create" element={<CreateUser />} />
                </Route>
                {/* this is the home page for the client */}
                <Route
                  index
                  element={
                    <AuthGuard redirectPath="login">
                      <Home />
                    </AuthGuard>
                  }
                />
              </Route>
              <Route path="404" element={<PageNotFound />} />
              <Route path="/" element={<PasswordResetResult />} />
              <Route
                path="/authenticate"
                element={
                  <NavigateWithParams to="../client/:client_lookup_name" />
                }
              />
              <Route path="*" element={<Navigate replace to="/404" />} />
            </Routes>
          </UserContextProvider>
        </GlobalContextProvider>
      </BrowserRouter>
    </div>
  );
}

export default App;
