import React, { useContext } from 'react';
import { Navigate, Outlet, useParams } from 'react-router-dom';

import { UserContext } from '../context/User';

export interface AuthGuardProps {
  children?: React.ReactNode;
  redirectPath: string;
  shouldBeLoggedIn?: boolean;
}
/**
 *
 * Guard component that checks if the user is logged in and should be logged in controlling access to the login page
 *
 * @params {AuthGuardProps}
 * @returns React.ReactNode - children/outlet or redirect to path if user should be logged in and is not or the inverse
 **/
const AuthGuard = ({
  children,
  redirectPath,
  shouldBeLoggedIn = true,
}: AuthGuardProps) => {
  const {
    user,
    sessionIsLoading: isLoading,
    authObject,
  } = useContext(UserContext);
  const shouldRedirect = shouldBeLoggedIn ? !user : user;
  const { client_lookup_name } = useParams();

  if (client_lookup_name === 'null') {
    return <Navigate to="/client/arena" />;
  }

  if (authObject?.accessToken && !user) {
    return <Navigate to={`/client/${client_lookup_name}/login`} />;
  }

  return (
    <>
      {!isLoading && shouldRedirect ? (
        <Navigate to={redirectPath} />
      ) : (
        <>
          {children}
          <Outlet />
        </>
      )}
    </>
  );
};

export default AuthGuard;
