import React from 'react';
import { useMediaQuery } from 'react-responsive';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
} from '@mui/material';

import { defaultTheme } from '../config';
import { Path, Theme } from '../types/talent-rerouting';
import CurrentPathChip from './CurrentPathChip';

const PathCard = ({
  path,
  theme,
  onChange,
  expanded,
  onSearchClick,
  userJobONet,
}: {
  path: Path;
  theme: Partial<Theme>;
  expanded: boolean;
  onChange: (expanded: string) => void;
  onSearchClick: (jobId: string) => void;
  userJobONet?: string;
}) => {
  const isTabletOrMobile = useMediaQuery({ minWidth: 650 });
  const borderColor = theme?.primaryColor ?? defaultTheme.primaryColor;
  const primaryImage = path.imageUrl ? path.imageUrl : '';
  const secondaryImage = path.secondaryImageUrl ? path.secondaryImageUrl : '';

  const pathChip = path.onetCodes?.some((code) =>
    (userJobONet + '').startsWith(code),
  ) && (
    <CurrentPathChip
      style={{ position: 'absolute', right: '8px', bottom: '12px' }}
    />
  );

  return (
    <div className="outside-accordion" style={{ maxWidth: '100vw' }}>
      <Accordion
        key={path.careerPathId}
        data-testid="path-card-accordion"
        sx={{
          margin: '24px',
          borderRadius: '8px',
          borderTop: 0,
          '&&.MuiPaper-root.MuiAccordion-root ': {
            borderRadius: '8px',
          },
          '&&.MuiPaper-root.MuiAccordion-root:: before ': {
            backgroundColor: 'transparent',
          },
          '&&.MuiPaper-root.MuiAccordion-root.Mui-expanded ': {
            outline: `solid 4px ${borderColor}`,
            margin: '0 24px ',
          },
        }}
        onChange={() => onChange(path.careerPathId)}
        expanded={expanded}
      >
        <AccordionSummary
          data-testid="path-card-summary"
          sx={{
            padding: '0',
            margin: '0',
            '& .MuiAccordionSummary-content': {
              margin: '0',
              padding: '0',
            },
            '& .MuiAccordionSummary-content.Mui-expanded': {
              margin: '0',
              padding: '0',
            },
          }}
        >
          <div style={{ width: '100%' }}>
            <div style={{ position: 'relative' }}>
              {primaryImage && (
                <img
                  data-testid="path-primary-image"
                  src={primaryImage}
                  alt={path.title}
                  style={{
                    objectPosition: 'center',
                    objectFit: 'cover',
                    maxHeight: isTabletOrMobile ? '20vh' : '100px',
                    borderTopLeftRadius: '4px',
                    borderTopRightRadius: '4px',
                    width: '100%',
                  }}
                />
              )}
              {primaryImage && pathChip}
            </div>
            {!primaryImage && pathChip}
            <div
              className="path-title"
              data-testid="path-title"
              style={{
                padding: path.title ? '16px' : '0',
                textAlign: 'left',
                color: theme?.primaryColor ?? 'black',
                fontWeight: '600',
              }}
            >
              {path.title}
            </div>
          </div>
        </AccordionSummary>
        <AccordionDetails sx={{ padding: '0' }}>
          <div
            style={{
              margin: '0 16px',
              textAlign: 'justify',
              whiteSpace: 'pre-wrap',
            }}
          >
            <div data-testid="path-description">{path.description}</div>
            <ul style={{ listStyleType: 'none' }}>
              {path.keyedDetails?.map((detail) => (
                <li key={detail.key}>{detail.value}</li>
              ))}
            </ul>
          </div>
          {path?.secondaryImageUrl && (
            <div
              style={{
                backgroundColor: '#FFF',
                margin: '16px 0',
              }}
            >
              <img
                data-testid="path-secondary-image"
                src={secondaryImage}
                alt={path.title}
                style={{
                  objectPosition: 'center',
                  objectFit: 'cover',
                  maxHeight: isTabletOrMobile ? '15vh' : '100px',
                  maxWidth: '100%',
                }}
              />
            </div>
          )}
          <Button
            data-testid="search-jobs-button"
            sx={{
              margin: '24px',
              color: borderColor,
              border: `1px solid ${borderColor}`,
              textTransform: 'none',
            }}
            variant={'outlined'}
            onClick={() => onSearchClick(path.careerPathId)}
          >
            Search Jobs
          </Button>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default PathCard;
