import { Auth0Error } from 'auth0-js';

import { webAuth } from '../auth/auth0.service';
import {
  AUTH0_AUDIENCE,
  AUTH0_CLIENT_ID,
  AUTH0_LOGIN_RESPONSE_TYPE,
  AUTH0_REALM,
  AUTH0_REDIRECT_URI,
} from '../config';
import {
  Auth0response,
  Auth0result,
  UserDetails,
  loginUserResponse,
  AuthResponse,
  CheckSessionResponse,
} from '../types/auth0';
import { getUserDetails } from './talent-rerouting';

const webAuthSignup = (
  email: string,
  password: string,
): Promise<Auth0response> => {
  return new Promise((resolve) => {
    webAuth.signup(
      {
        email: email,
        password: password,
        connection: AUTH0_REALM,
      },
      async (error: Auth0Error | null, result: Auth0result) => {
        if (error !== null) {
          resolve({ error, result: null });
        } else {
          resolve({ error: null, result });
        }
      },
    );
  });
};

export const createUser = async (
  userDetails: UserDetails,
  client: string,
): Promise<loginUserResponse> => {
  const { email, password } = userDetails;
  // call to sign up api to create user in auth0 https://auth0.com/docs/libraries/auth0js#signup
  try {
    const resultAuth = await webAuthSignup(email, password);
    if (resultAuth.error) {
      return { error: resultAuth.error, authObject: null };
    }
    return await loginUser(email, password, client);
  } catch (e) {
    return { error: e, authObject: null };
  }
};

const logInPromise = (
  email: string,
  password: string,
  client: string,
): Promise<{ error: Auth0Error | null; result: AuthResponse | null }> => {
  return new Promise((resolve) => {
    // call to webAuth.login to login user https://auth0.com/docs/libraries/auth0js#webauth-login-
    webAuth.login(
      {
        username: email,
        password: password,
        realm: AUTH0_REALM,
        redirectUri: `${AUTH0_REDIRECT_URI}?client=${client}`,
        responseType: AUTH0_LOGIN_RESPONSE_TYPE,
        audience: AUTH0_AUDIENCE, // required for a non-Opaque Access Token
      },
      async (error: Auth0Error | null, result: AuthResponse) => {
        if (error !== null) {
          resolve({ error, result: null });
        } else {
          resolve({ error: null, result });
        }
      },
    );
  });
};

export const loginUser = async (
  email: string,
  password: string,
  client: string,
): Promise<loginUserResponse> => {
  try {
    const result = await logInPromise(email, password, client);
    if (result.error) {
      return { error: result.error, authObject: null };
    }
    return { error: null, authObject: result.result };
  } catch (e) {
    return { error: e, authObject: null };
  }
};

// opens an Iframe and checks the session
// redirectUri is only used to check aut0 allowed urls (configured with auth0)
export const checkSession = async (
  clientLookupName?: string,
): Promise<CheckSessionResponse> => {
  return new Promise((resolve) => {
    webAuth.checkSession(
      {
        responseType: AUTH0_LOGIN_RESPONSE_TYPE,
        redirectUri: AUTH0_REDIRECT_URI,
        audience: AUTH0_AUDIENCE,
      },

      async (err, authResult) => {
        if (err) {
          resolve({ error: err, authObject: null, user: null });
        } else {
          const user = await getUserDetails(
            {
              ...authResult.idTokenPayload,
              clientLookupName: clientLookupName,
            },
            authResult.accessToken,
            clientLookupName,
          );
          resolve({ error: null, authObject: authResult, user });
        }
      },
    );
  });
};

export const logoutUser = async (client_lookup_name: string) => {
  webAuth.logout({
    returnTo: `${AUTH0_REDIRECT_URI}?client=${client_lookup_name}`,
    clientID: AUTH0_CLIENT_ID,
  });
};

export const changePasswordRequest = async (
  email: string,
): Promise<{ error: Auth0Error | null; success: boolean }> => {
  return new Promise((resolve) => {
    webAuth.changePassword(
      {
        email: email,
        connection: AUTH0_REALM,
      },
      (err) => {
        if (err) {
          resolve({ error: err, success: false });
        } else {
          resolve({ error: null, success: true });
        }
      },
    );
  });
};
