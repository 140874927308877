import React, { createContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { checkSession } from '../api/auth0';
import { AuthResponse } from '../types/auth0';
import { User, SavedJob } from '../types/talent-rerouting';

export interface UserContextData {
  user: User | null;
  userJobs: SavedJob[];
  authObject: AuthResponse | null;
  setLoggedInUser: (user: User) => void;
  removeUserFromContext: () => void;
  sessionIsLoading: boolean;
  setSessionIsLoading: (loading: boolean) => void;
  setUserJobs: (jobs: SavedJob[]) => void;
}

// initial state
export const UserContext = createContext<UserContextData>({
  user: null,
  userJobs: [],
  authObject: null,
  sessionIsLoading: true,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setLoggedInUser: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  removeUserFromContext: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setSessionIsLoading: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setUserJobs: () => {},
});

const UserContextProvider = ({ children }) => {
  const [user, setUser] = useState<User | null>(null);
  const [userJobs, setUserJobs] = useState<SavedJob[]>([]);
  const [authObject, setAuthObject] = useState<AuthResponse | null>(null);
  const [sessionIsLoading, setSessionIsLoading] = useState(true);

  const navigate = useNavigate();

  // check if user is logged in by using the Auth0 checkSession method, if user is logged in
  // set the user in the context and set loading to false

  useEffect(() => {
    const loadUser = async () => {
      const client_lookup_name = window.location.pathname
        .split('/')
        .filter((x) => x)[1];

      try {
        const u = await checkSession(client_lookup_name);
        if (u && !u.error && u.user) {
          setUser(u.user);
          setUserJobs(u?.user?.savedJobs ?? []);
          setAuthObject(u.authObject);
          setSessionIsLoading(false);

          const client = u.user.clientLookupName ?? client_lookup_name;
          if (u.user.screeningUUID === null || u.user.needRegistration) {
            navigate(`/client/${client}/register`);
          } else if (u.user.needsScreening) {
            navigate(`/client/${client}/questionnaire`);
          } else {
            navigate(`/client/${u.user.clientLookupName}`);
          }
        }
        setSessionIsLoading(false);
      } catch (e) {
        setSessionIsLoading(false);
        console.error(e);
      }
    };

    loadUser();
  }, []);

  const setLoggedInUser = (user) => {
    setSessionIsLoading(false);
    setUser(user);
  };
  const removeUserFromContext = () => {
    setSessionIsLoading(false);
    setUser(null);
  };

  return (
    <UserContext.Provider
      value={{
        user,
        userJobs,
        setUserJobs,
        setLoggedInUser,
        removeUserFromContext,
        sessionIsLoading,
        setSessionIsLoading,
        authObject,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export default UserContextProvider;
